<template>
  <header id="header" class="header header-bg-transparent header-abs-top header-white-nav-links-lg mt-3">
    <div class="js-mega-menu header-section">
      <div id="logoAndNav" class="container">
        <!-- Nav -->
        <nav class="navbar navbar-expand-lg">
          <!-- Logo -->
          <router-link class="navbar-brand" to="/" aria-label="Savelocal"> <!-- v-if="router.currentRoute.value.name!=='Landing'"-->
            <img src="../../public/assets/img/logo.png" alt="Logo" style="opacity:0.85">
          </router-link>
          <!-- End Logo -->

          <!-- Responsive Toggle Button -->
          <button type="button" class="navbar-toggler btn btn-icon btn-sm rounded-circle"
                  aria-label="Toggle navigation"
                  aria-expanded="false"
                  aria-controls="navBar"
                  data-toggle="collapse"
                  data-target="#navBar">
            <span class="navbar-toggler-default">
              <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"/>
              </svg>
            </span>
            <span class="navbar-toggler-toggled">
              <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
              </svg>
            </span>
          </button>
          <!-- End Responsive Toggle Button -->

          <!-- Navigation -->
          <div id="navBar" class="collapse navbar-collapse">
            <ul class="navbar-nav">
              <!-- Pages
              <li class="navbar-nav-item">
                <a class="nav-link" href="/"><i class="fa fa-play-circle d-none d-sm-inline"></i>&nbsp;How it works?</a>
              </li> -->
              <!-- <li class="navbar-nav-item">
                <a class="nav-link" v-bind:class="{ 'active': this.$route.name==='Landing' }" href="/">Outdoor lovers</a>
              </li>
              <li class="navbar-nav-item">
                <a class="nav-link" v-bind:class="{ 'active': this.$route.name==='Nonprofit' }" href="/nonprofit">Nonprofits</a>
              </li>
              <li class="navbar-nav-item">
                <a class="nav-link" v-bind:class="{ 'active': this.$route.name==='Ambassadors' }" href="/ambassadors">Ambassadors</a>
              </li>
              <li class="navbar-nav-item">
                <a class="nav-link" v-bind:class="{ 'active': this.$route.name==='Developers' }" href="/developers">Developers</a>
              </li>
              <li class="navbar-nav-item">
                <a class="nav-link" v-bind:class="{ 'active': this.$route.name==='About' }" href="/about">About</a>
              </li>
              <li class="navbar-nav-item">
                <a class="nav-link" v-bind:class="{ 'active': this.$route.name==='Contact' }" href="/contact">Contact</a>
              </li> -->
              <li class="navbar-nav-item">
                <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='How' }" to="/how">How does it work?</router-link>
              </li>
              <li class="navbar-nav-item" v-if="!store.state.uid">
                <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Nonprofits' }" to="/nonprofits">Nonprofits</router-link>
              </li>
              <li class="navbar-nav-item" v-if="store.state.uid">
                <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Feed' }" to="/feed">
                  Feed <span class="badge badge-soft-danger badge-pill nav-link-badge">2</span>
                </router-link>
              </li>
              <li class="navbar-nav-item" v-if="store.state.uid">
                <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Explore' }" to="/explore">Explore</router-link>
              </li>
              <li class="navbar-nav-item" v-if="store.state.uid">
                <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Settings' }" to="/settings">Settings</router-link>
              </li>
              <li class="navbar-nav-item">
                <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Contact' }" to="/contact">Contact</router-link>
              </li>
              <!-- End Pages -->

              <!-- Button -->
              <li class="navbar-nav-last-item" v-if="store.state.uid">
                <a class="btn btn-sm btn-dark transition-3d-hover" @click="signOut()">
                  <i class="fa fa-running"></i>&nbsp;
                  Log out
                </a>
              </li>
              <li class="navbar-nav-last-item" v-if="!store.state.uid && false">
                <a class="btn btn-sm btn-soft-primary" href="/feed">
                  <i class="fa fa-user-circle"></i>&nbsp;
                  Log in
                </a>
              </li>
              <!-- End Button -->
            </ul>
          </div>
          <!-- End Navigation -->
        </nav>
        <!-- End Nav -->
      </div>
    </div>
  </header>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const signOut = () => { store.dispatch('signOut').then(() => { router.push('/')} ) }
</script>