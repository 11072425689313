import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics'

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyBzm15J4iZYVkAWrHREKvummu74Vw5XjAk",
  authDomain: "savelocal-12d5e.firebaseapp.com",
  projectId: "savelocal-12d5e",
  storageBucket: "savelocal-12d5e.appspot.com",
  messagingSenderId: "15672225141",
  appId: "1:15672225141:web:805807915a399e220c773c",
  measurementId: "G-XLMSNKS9GE"
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.functions()
firebase.functions().useEmulator("localhost", 5000);

// collection references
const usersCollection = db.collection('users')
const organizationsCollection = db.collection('organizations')
const projectsCollection = db.collection('projects')

// export utils/refs
export {
  db,
  auth,
  functions,
  usersCollection,
  organizationsCollection,
  projectsCollection
}