import { createStore } from 'vuex'
import * as fb from '../firebase'
// import router from '../router'
// USE VUEFIRE WHEN IT SUPPORTS VUE3

// const generatePassword = () => {
//   var length = 12,
//       charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()[]{}",
//       retVal = "";
//   for (var i = 0, n = charset.length; i < length; ++i) {
//       retVal += charset.charAt(Math.floor(Math.random() * n));
//   }
//   return retVal;
// }

const store = createStore({
  state () {
    return {
      uid: '',
      userProfile: {},
      organizations: {},
      projects: []
    }
  },
  mutations: {
    SET_USER_PROFILE(state, value) {
      state.userProfile = value
    },
    SET_UID(state, value) {
      state.uid = value
    },
    SET_ORGANIZATIONS(state, value) {
      state.organizations = value
    },
    SET_PROJECTS(state, value) {
      state.projects = value
    },
    SET_FETCHED_ORGANIZATIONS(state, value) {
      state.organizations = value
    },
    SET_FETCHED_PROJECTS(state, value) {
      state.organizations = value
    },
  },
  actions: {
    async fetchUserProfile({ commit }, user) {
      commit('SET_UID', user.uid)
      const userProfile = await fb.usersCollection.doc(user.uid).get()
      const up = userProfile.data()
      up.email = user.email
      commit('SET_USER_PROFILE', up)
    },
    async updateUserProfile({ commit, state }, up) {
      await fb.usersCollection.doc(state.uid).set(up)
      commit('SET_USER_PROFILE', up)
    },
    async signOut({ commit }) {
      await fb.auth.signOut()
      commit('SET_UID', '')
      commit('SET_USER_PROFILE', {})
    },
    // async signIn({ dispatch }, form) {
    //   const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
    //   dispatch('fetchUserProfile', user)
    // },
    // async signUp({ dispatch }, form) {
    //   //const password = generatePassword()
    //   const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
    //   await fb.usersCollection.doc(user.uid).set({
    //     firstName: form.name
    //   })
    //   dispatch('fetchUserProfile', user)
    // },
    async fetchOrganizations({ commit }) {
      if(Object.entries(store.state.organizations).length === 0){
        const querySnapshot = await fb.organizationsCollection.get();
        let organizations = {}
        querySnapshot.forEach((doc) => {
          organizations[doc.id]=doc.data()
        });
        commit('SET_ORGANIZATIONS', organizations)
      }
    },
    async fetchProjects({ commit }) {
      if(store.state.projects.length === 0){
        const querySnapshot = await fb.projectsCollection.get();
        let projects = []
        querySnapshot.forEach((doc) => {
          let project = doc.data()
          project.id = doc.id
          projects.push(project)
        });
        commit('SET_PROJECTS', projects)
      }
    }
  }
})

export default store