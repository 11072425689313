import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Landing',
            component: () => import('../views/Landing.vue'),
            meta: {header: true, footer: false, requiresAuth: false}
        },
        {
            path: '/how',
            name: 'How',
            component: () => import('../views/How.vue'),
            meta: {header: true, footer: false, requiresAuth: false}
        },
        {
            path: '/nonprofits',
            name: 'Nonprofits',
            component: () => import('../views/Nonprofits.vue'),
            meta: {header: true, footer: false, requiresAuth: false}
        },
        {
            path: '/contact',
            name: 'Contact',
            component: () => import('../views/Contact.vue'),
            meta: {header: true, footer: false, requiresAuth: false}
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('../views/Login.vue'),
            meta: {header: false, footer: false, requiresAuth: false}
        },
        {
            path: '/register',
            name: 'Register',
            component: () => import('../views/Register.vue'),
            meta: {header: true, footer: false, requiresAuth: true}
        },
        {
            path: '/feed',
            name: 'Feed',
            component: () => import('../views/Feed.vue'),
            meta: {header: true, footer: false, requiresAuth: true}
        },
        {
            path: '/explore',
            name: 'Explore',
            component: () => import('../views/Explore.vue'),
            meta: {header: true, footer: false, requiresAuth: true}
        },
        {
            path: '/settings',
            name: 'Settings',
            component: () => import('../views/Settings.vue'),
            meta: {header: true, footer: false, requiresAuth: true},
            children: [
                {
                    path: '',
                    redirect: '/settings/profile',
                },
                {
                    path: 'profile',
                    name: 'Profile',
                    props: {
                        next: 'Save',
                        col: true
                    },
                    component: () => import('../components/settings/Profile.vue')
                },
                {
                    path: 'payments',
                    name: 'Payments',
                    component: () => import('../views/settings/Payments.vue')
                },
                {
                    path: 'integrations',
                    name: 'Integrations',
                    component: () => import('../views/settings/Integrations.vue')
                },
                {
                    path: 'default',
                    name: 'Default',
                    component: () => import('../views/settings/Default.vue')
                },
                {
                    path: 'notifications',
                    name: 'Notifications',
                    component: () => import('../views/settings/Notifications.vue')
                },
                {
                    path: 'skills',
                    name: 'Skills',
                    component: () => import('../views/settings/Skills.vue')
                }
              ]
        }
    ]
})

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  
    if (requiresAuth && !auth.currentUser) {
        sessionStorage.setItem('redirectPath', to.path);
        next('/login')
    } else {
        next()
    }
})

export default router